import { DataTablePFSEvent } from 'primereact/datatable'
import { FC, useCallback, useEffect } from 'react'

import { FormikProps } from 'formik'
import { onFormikChanges, useTranslator } from 'ufinet-web-functions'

import { faSearch } from '@fortawesome/free-solid-svg-icons'

import {
	ClientSelect,
	ClientSelectHandle,
	CorporateGroupSelect,
	CorporateGroupSelectHandle,
	CountrySelect,
	DatePicker,
	DatePickerTypeEnum,
	UfinetButton,
	UfinetSectionBox,
} from 'ufinet-web-components'

interface FormikValues extends DataTablePFSEvent {}

interface ListIncidentHeaderProps {
	formik: FormikProps<FormikValues>
	tablePFSEvent: DataTablePFSEvent
	clientSelectRef: React.RefObject<ClientSelectHandle>
	groupSelectRef: React.RefObject<CorporateGroupSelectHandle>
	findTickets: (event: DataTablePFSEvent) => void
}

const ListIncidentHeader: FC<ListIncidentHeaderProps> = ({
	formik,
	tablePFSEvent,
	clientSelectRef,
	groupSelectRef,
	findTickets,
}) => {
	const translate = useTranslator()
	const onChange = useCallback(onFormikChanges, [])
	useEffect(() => {
		if (formik?.values?.countryId?.value) {
			groupSelectRef?.current?.fillByCountries([formik.values.countryId.value])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.countryId])

	/*	useEffect(() => {
		clientSelectRef.current?.fillSelect([formik?.values?.countryId?.value], [formik?.values?.corporateGroupId?.value])
		formik.setFieldValue('customersId', [])
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.countryId, formik.values.corporateGroupId])
	*/
	return (
		<UfinetSectionBox>
			<div className="row">
				<CountrySelect
					className="col-md-4 col-lg-4 col-xl-4 col-xxl-2 mt-sm-3 mt-md-0"
					value={formik.values.countryId}
					onChange={onChange(formik, 'countryId')}
				/>
				<CorporateGroupSelect
					isMulti
					ref={groupSelectRef}
					className="col-md-4 col-lg-4 col-xl-4 col-xxl-2 mt-sm-3 mt-md-0"
					value={formik.values.corporateGroupId}
					onChange={onChange(formik, 'corporateGroupId')}
					requiredIcon={false}
				/>
				<ClientSelect
					className="col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-sm-3 mt-md-0"
					isMulti
					ref={clientSelectRef}
					value={formik.values.customersId}
					onChange={onChange(formik, 'customersId')}
					requiredIcon={false}
				/>
				<DatePicker
					className="col-md-6 col-lg-6 col-xl-6 col-xxl-2 mt-sm-3 mt-xl-0"
					type={DatePickerTypeEnum.DATE_ONLY}
					value={formik.values.dateFrom}
					onChange={(e) => formik.setFieldValue('dateFrom', (e.value as Date) || undefined)}
					label={translate('FILTER.TICKET.DATE_FROM')}
					error={formik.errors?.dateFrom?.toString()}
					showCalendarIcon
					max={new Date()}
					id="dateFromPicker"
				/>
				<DatePicker
					className="col-md-6 col-lg-6 col-xl-6 col-xxl-2 mt-sm-3 mt-xl-0"
					type={DatePickerTypeEnum.DATE_ONLY}
					value={formik.values.dateTo}
					onChange={(e) => formik.setFieldValue('dateTo', (e.value as Date) || undefined)}
					label={translate('FILTER.TICKET.DATE_TO')}
					showCalendarIcon
					error={formik?.errors?.dateTo?.toString()}
					max={new Date()}
					id="dateToPicker"
				/>
				<p className="text-danger" style={{ paddingTop: '1.6%' }}>
					{formik.errors.dateFrom && translate('ERROR.VALIDATION.DATE_RANGE')}
				</p>

				<div className="mb-sm-3 mb-md-0">
					<UfinetButton
						type="button"
						icon={faSearch}
						id="refresh-button"
						content={translate('BUTTON.SEARCH')}
						onClick={() => findTickets(tablePFSEvent)}
					/>
				</div>
			</div>
		</UfinetSectionBox>
	)
}

export { ListIncidentHeader }
