import { useQuery } from 'react-query'

import { ReportOrigin } from '../domain/ReportOrigin'
import { ReportOriginRepository } from '../domain/repository/ReportOriginRepository'

export const useReportOriginFindAllQuery = (
	reportOriginRepository: ReportOriginRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (reportOrigin: ReportOrigin[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('report-origin-find-all-query', () => reportOriginRepository.findAll(), {
		staleTime: 0,
		onSuccess,
		onError,
	})
}
