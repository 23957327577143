import { FC } from 'react'

import { useTranslator } from 'ufinet-web-functions'

import { UfinetInput, UfinetSectionBox } from 'ufinet-web-components'

interface DetailTicketClosedProps {
	resetDate: string
	closeDate: string
	solutionType: string
	primaryCause: string
	secondaryCause: string
	confirmedWith: string | null
	latitudeLongitudeFailure: string
	rfoSummary: string | null
	rfoSentDate: string
	additionalComments: string | null
	attributableTo: string
	discountTime: number | null
	discountReason: string | null
}

const DetailTicketClosed: FC<DetailTicketClosedProps> = ({
	resetDate,
	closeDate,
	solutionType,
	primaryCause,
	secondaryCause,
	confirmedWith,
	latitudeLongitudeFailure,
	rfoSummary,
	rfoSentDate,
	additionalComments,
	attributableTo,
	discountTime,
	discountReason,
}) => {
	const translate = useTranslator()

	return (
		<UfinetSectionBox title="ticket_closed" className="mb-5 p-5">
			<h4 className="ufinet-color-text">Cierre del ticket</h4>
			<div className="row">
				<UfinetInput
					className="col-12 col-md-4 mt-3"
					labelTitle={translate('TICKET_DETAILS.RESET_DATE')}
					type="text"
					value={resetDate}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md-4 mt-3"
					labelTitle={translate('TICKET_DETAILS.CLOSE_DATE')}
					type="text"
					value={closeDate}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md-4 mt-3"
					labelTitle={translate('TICKET_DETAILS.SOLUTION_TYPE')}
					type="text"
					value={solutionType}
					isDisabled
					solid={false}
				/>
			</div>
			<div className="row">
				<UfinetInput
					className="col-12 col-md-3 mt-3"
					labelTitle={translate('TICKET_DETAILS.PRIMARY_CAUSE')}
					type="text"
					value={primaryCause}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md-3 mt-3"
					labelTitle={translate('TICKET_DETAILS.SECONDARY_CAUSE')}
					type="text"
					value={secondaryCause}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md-3 mt-3"
					labelTitle={translate('TICKET_DETAILS.CONFIRMED_WITH')}
					type="text"
					value={confirmedWith ?? ''}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md-3 mt-3"
					labelTitle={translate('TICKET_DETAILS.FAIL_LAT_LONG')}
					type="text"
					value={latitudeLongitudeFailure}
					isDisabled
					solid={false}
				/>
			</div>
			<div className="row">
				<UfinetInput
					className="col-12 col-md-9 mt-3"
					labelTitle={translate('TICKET_DETAILS.RFO_SUMMARY')}
					type="text"
					value={rfoSummary ?? ''}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md-3 mt-3"
					labelTitle={translate('TICKET_DETAILS.RFO_SENT_DATE')}
					type="text"
					value={rfoSentDate}
					isDisabled
					solid={false}
				/>
			</div>
			<div className="row">
				<UfinetInput
					className="col mt-3"
					labelTitle={translate('TICKET_DETAILS.ADDITIONAL_COMMENTS')}
					type="text"
					value={additionalComments ?? ''}
					isDisabled
					solid={false}
				/>
			</div>
			<div className="row">
				<UfinetInput
					className="col-12 col-md-4 mt-3"
					labelTitle={translate('TICKET_DETAILS.ATTRIBUTABLE_TO')}
					type="text"
					value={attributableTo}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md-4 mt-3"
					labelTitle={translate('TICKET_DETAILS.DISCOUNT_TIME')}
					type="text"
					value={discountTime ?? ''}
					isDisabled
					solid={false}
				/>
				<UfinetInput
					className="col-12 col-md-4 mt-3"
					labelTitle={translate('TICKET_DETAILS.DISCOUNT_REASON')}
					type="text"
					value={discountReason ?? ''}
					isDisabled
					solid={false}
				/>
			</div>
		</UfinetSectionBox>
	)
}

export { DetailTicketClosed }
