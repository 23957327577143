import { useContext, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { useAffectionTypeFindAllQuery } from 'src/modules/affectationTypes/application/AffectationTypeQueries'
import { AffectationType } from 'src/modules/affectationTypes/domain/AffectationType'
import { HttpAffectationTypeRepository } from 'src/modules/affectationTypes/infrastructure/HttpAffectationTypeRepository'
import { useDegradationTypeFindAllQuery } from 'src/modules/degradationTypes/application/DegradationTypeQueries'
import { DegradationType } from 'src/modules/degradationTypes/domain/DegradationType'
import { HttpDegradationTypeRepository } from 'src/modules/degradationTypes/infrastructure/HttpDegradationTypeRepository'
import { useProcessStatusFindAllQuery } from 'src/modules/processStatus/application/ProcessStatusQueries'
import { ProcessStatus } from 'src/modules/processStatus/domain/ProcessStatus'
import { HttpProcessStatusRepository } from 'src/modules/processStatus/infrastructure/HttpProcessStatusRepository'
import { useProjectTypeFindAllQuery } from 'src/modules/projectTypes/application/ProjectTypeQueries'
import { ProjectType } from 'src/modules/projectTypes/domain/ProjectType'
import { HttpProjectTypeRepository } from 'src/modules/projectTypes/infrastructure/HttpProjectTypeRepository'
import { useReportOriginFindAllQuery } from 'src/modules/reportOrigins/application/ReportOriginQueries'
import { ReportOrigin } from 'src/modules/reportOrigins/domain/ReportOrigin'
import { HttpReportOriginRepository } from 'src/modules/reportOrigins/infrastructure/HttpReportOriginRepository'
import { useReportTypeFindAllQuery } from 'src/modules/reportTypes/application/ReportTypeQueries'
import { ReportType } from 'src/modules/reportTypes/domain/ReportType'
import { HttpReportTypeRepository } from 'src/modules/reportTypes/infrastructure/HttpReportTypeRepository'
import { useServiceTypeFindAllQuery } from 'src/modules/serviceTypes/application/ServiceTypeQueries'
import { ServiceType } from 'src/modules/serviceTypes/domain/ServiceType'
import { HttpServiceTypeRepository } from 'src/modules/serviceTypes/infrastructure/HttpServiceTypeRepository'
import { useSolutionTypeFindAllQuery } from 'src/modules/solutionTypes/application/SolutionTypeQueries'
import { SolutionType } from 'src/modules/solutionTypes/domain/SolutionType'
import { HttpSolutionTypeRepository } from 'src/modules/solutionTypes/infrastructure/HttpSolutionTypeRepository'
import { AuthContext, IUfinetSelectOption, useLang, useTranslator } from 'ufinet-web-functions'
import { RFO_SENT_STATUS } from 'src/utils/constants'

export const useListDataQueries = () => {
	const [reportTypes, setReportTypes] = useState<IUfinetSelectOption[]>([])
	const [degradationTypes, setDegradationTypes] = useState<IUfinetSelectOption[]>([])
	const [affectationTypes, setAffectationTypes] = useState<IUfinetSelectOption[]>([])
	const [projectTypes, setProjectTypes] = useState<IUfinetSelectOption[]>([])
	const [processStatus, setProcessStatus] = useState<IUfinetSelectOption[]>([])
	const [reportOrigins, setReportOrigins] = useState<IUfinetSelectOption[]>([])
	const [rfoSentStatus, setRfoSentStatus] = useState<IUfinetSelectOption[]>([])
	const [serviceTypes, setServiceTypes] = useState<IUfinetSelectOption[]>([])
	const [solutionTypes, setSolutionTypes] = useState<IUfinetSelectOption[]>([])

	const authData = useContext(AuthContext)
	const lang = useLang()
	const translate = useTranslator()

	useMemo(() => {
		setRfoSentStatus([RFO_SENT_STATUS(translate).NOT_SENT, RFO_SENT_STATUS(translate).SENT])
	}, [translate])

	const reportTypeRepository = useMemo(() => HttpReportTypeRepository(authData), [authData])
	const degradationTypeRepository = useMemo(() => HttpDegradationTypeRepository(authData), [authData])
	const affectationTypeRepository = useMemo(() => HttpAffectationTypeRepository(authData), [authData])
	const projectTypeRepository = useMemo(() => HttpProjectTypeRepository(authData), [authData])
	const processStatusRepository = useMemo(() => HttpProcessStatusRepository(authData), [authData])
	const reportOriginRepository = useMemo(() => HttpReportOriginRepository(authData), [authData])
	const serviceTypeRepository = useMemo(() => HttpServiceTypeRepository(authData), [authData])
	const solutionTypeRepository = useMemo(() => HttpSolutionTypeRepository(authData), [authData])

	useReportTypeFindAllQuery(reportTypeRepository, {
		onSuccess: (params: ReportType[]): void => {
			const reportTypes = params.map((item) => ReportType.mapReportTypeToSelectOption(item, lang))
			setReportTypes(reportTypes)
		},
		onError: () => toast.error(translate('FETCH.ERROR.REPORT_TYPE')),
	})

	useDegradationTypeFindAllQuery(degradationTypeRepository, {
		onSuccess: (params: DegradationType[]): void => {
			const degradationTypes = params.map((item) => DegradationType.mapDegradationTypeToSelectOption(item, lang))
			setDegradationTypes(degradationTypes)
		},
		onError: () => toast.error(translate('FETCH.ERROR.DEGRADATION_TYPE')),
	})

	useAffectionTypeFindAllQuery(affectationTypeRepository, {
		onSuccess: (params: AffectationType[]): void => {
			const affectationTypes = params.map((item) => AffectationType.mapAffectationTypesToSelectOption(item, lang))
			setAffectationTypes(affectationTypes)
		},
		onError: () => toast.error(translate('FETCH.ERROR.AFFECTED_SERVICE')),
	})

	useProjectTypeFindAllQuery(projectTypeRepository, {
		onSuccess: (params: ProjectType[]): void => {
			const projectTypes = params.map((item) => ProjectType.mapProjectTypeToSelectOption(item, lang))
			setProjectTypes(projectTypes)
		},
		onError: () => toast.error(translate('FETCH.ERROR.PROJECT_TYPE')),
	})

	useProcessStatusFindAllQuery(processStatusRepository, {
		onSuccess: (params: ProcessStatus[]): void => {
			const processStatus = params.map((item) => ProcessStatus.mapProcessStatusToSelectOption(item, lang))
			setProcessStatus(processStatus)
		},
		onError: () => toast.error(translate('FETCH.ERROR.PROJECT_STATUS')),
	})

	useReportOriginFindAllQuery(reportOriginRepository, {
		onSuccess: (params: ReportOrigin[]): void => {
			const reportOrigins = params.map((item) => ReportOrigin.mapReportOriginToSelectOption(item, lang))
			setReportOrigins(reportOrigins)
		},
		onError: () => toast.error(translate('FETCH.ERROR.REPORT_ORIGINS')),
	})

	useServiceTypeFindAllQuery(serviceTypeRepository, {
		onSuccess: (params: ServiceType[]) => {
			const serviceTypes = params.map((item) => ServiceType.mapServiceTypeToSelectOption(item, lang))
			setServiceTypes(serviceTypes)
		},
		onError: () => toast.error(translate('FETCH.ERROR.SERVICE_TYPE')),
	})

	useSolutionTypeFindAllQuery(solutionTypeRepository, {
		onSuccess: (params: SolutionType[]) => {
			const solutionTypes = params.map((item) => SolutionType.mapSolutionTypeToSelectOption(item, lang))
			setSolutionTypes(solutionTypes)
		},
		onError: () => toast.error(translate('FETCH.ERROR.SOLUTION_TYPE')),
	})

	return {
		reportTypes,
		degradationTypes,
		affectationTypes,
		projectTypes,
		processStatus,
		reportOrigins,
		rfoSentStatus,
		serviceTypes,
		solutionTypes,
	}
}
