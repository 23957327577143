import { FC, useContext, useMemo, useState } from 'react'

import { FormikProps, useFormik } from 'formik'
import { useAttachmentConfigFindAllQuery } from 'src/modules/attachmentConfig/application/AttachmentConfigQueries'
import { HttpAttachmentConfigRepository } from 'src/modules/attachmentConfig/infrastructure/HttpAttachmentConfigRepository'
import { Attachments } from 'src/modules/incidents/application/dto/CreateIncidentActivityDTO'
import { IncidentActivityDTO } from 'src/modules/incidents/application/dto/IncidentActivityDTO'
import { VALID_TICKET_STATUS_IDS } from 'src/utils/constants'
import { AuthContext, useTranslator } from 'ufinet-web-functions'
import * as Yup from 'yup'

import Pagination from '@Pages/tickets/details/components/detailTicketNotes/components/Pagination'
import { Loading, UfinetBox, UfinetButton, UfinetSectionBox } from 'ufinet-web-components'
import { Note } from './components/Note'
import { NotesModal } from './components/NotesModal'

type DetailTicketNotesProps = {
	activities: IncidentActivityDTO[]
	loading: boolean
	currentPage: number
	totalPages: number
	onPageChange: (newPage: number) => void
	onCreateComment: (subject: string, description: string, attachments?: Attachments) => Promise<void>
	isTicketInProcess: boolean
	ticketStatusId: number
	isCreatingIncidentActivity: boolean
}

type FormikValues = {
	subject: string
	description: string
	files: File[]
}

const DetailTicketNotes: FC<DetailTicketNotesProps> = ({
	activities = [],
	loading,
	currentPage,
	// totalPages,
	onPageChange,
	onCreateComment,
	isTicketInProcess,
	ticketStatusId,
	isCreatingIncidentActivity,
}) => {
	const [openModal, setOpenModal] = useState<boolean>(false)
	const translate = useTranslator()
	const itemsPerPage = 5

	const authData = useContext(AuthContext)
	const attachmentRepository = useMemo(() => HttpAttachmentConfigRepository(authData), [authData])

	const { data: allowedFiles } = useAttachmentConfigFindAllQuery(attachmentRepository)
	const MAX_SIZE = Number(allowedFiles?.find((file) => file.name === 'MAX_SIZE')?.value ?? 25) * 1024 * 1024
	const bytesToMB = (bytes: number) => bytes / (1024 * 1024)

	const isValidFileType = (file: File) => {
		return allowedFiles?.some((allowedFile) => allowedFile.mime === file.type)
	}

	const paginatedActivities = useMemo(() => {
		const startIndex = (currentPage - 1) * itemsPerPage
		const endIndex = startIndex + itemsPerPage
		return activities.slice(startIndex, endIndex)
	}, [activities, currentPage])

	const totalPagesCalculated = useMemo(() => {
		return Math.ceil(activities.length / itemsPerPage)
	}, [activities])

	const initialValues = {
		subject: '',
		description: '',
		files: [] as File[],
	}

	const validationSchema = Yup.object({
		subject: Yup.string().required(translate('ERROR.REQUIRED')),
		description: Yup.string().required(translate('ERROR.REQUIRED')),
		files: Yup.array()
			.of(Yup.mixed())
			.test('fileSize', `${translate('TICKET_DETAILS.MAX_FILES_SIZE')} ${bytesToMB(MAX_SIZE)}Mb`, (files) => {
				if (files && Array.isArray(files) && files.length > 0) {
					const totalSize = files.reduce((acc: number, file) => acc + (file instanceof File ? file.size : 0), 0)
					return totalSize <= MAX_SIZE
				}
				return true
			})
			.test('fileType', `${translate('TICKET_DETAILS.BAD_FILE_TYPE')}`, (files) => {
				if (files && files.length > 0) {
					return files.every((file) => file instanceof File && isValidFileType(file))
				}
				return true
			}),
	})

	const findFileConfig = (fileExtension: string) => {
		return (allowedFiles ?? []).find((config) => config.value === fileExtension)
	}

	const fileToBase64 = (file: File): Promise<string> => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader()
			reader.onloadend = () => {
				if (reader.result) {
					if (typeof reader.result === 'string') {
						resolve(reader.result.split(',')[1]) // Remover "data:*/*;base64,"
					} else {
						reject(new Error('File reading failed'))
					}
				} else {
					reject(new Error('File reading failed'))
				}
			}
			reader.onerror = reject
			reader.readAsDataURL(file)
		})
	}

	const createNote = async (values: FormikValues) => {
		setOpenModal(false)

		const parsedFiles = await Promise.all(
			values.files.map(async (file) => {
				const fileExtension = file.name.slice(file.name.lastIndexOf('.'))
				const mime = findFileConfig(fileExtension)?.mime
				const fileContent = await fileToBase64(file)

				return {
					fileName: file.name,
					fileExtension,
					fileContent,
					mime,
				}
			})
		)
		onCreateComment(values.subject, values.description, parsedFiles)
		formik.resetForm()
	}

	const handleClose = () => {
		formik.resetForm()
		setOpenModal(false)
	}

	const formik: FormikProps<FormikValues> = useFormik({
		initialValues,
		validationSchema,
		onSubmit: createNote,
		validateOnChange: false,
		validateOnBlur: true,
		enableReinitialize: true,
	})

	if (loading) {
		return (
			<UfinetBox className="detail-loading-container">
				<Loading userClasses="detail-loading" />
			</UfinetBox>
		)
	}

	return (
		<>
			<UfinetSectionBox title="ticket_notes" className="mb-5">
				<div className="d-flex justify-content-between align-items-center">
					<h4 className="ufinet-color-text">{translate('TICKET_DETAILS.COMMENTS')}</h4>
					{VALID_TICKET_STATUS_IDS.includes(ticketStatusId) && (
						<div className="d-flex justify-content-end">
							<UfinetButton
								content={translate('TICKET_DETAILS.ADD_COMMENT')}
								onClick={() => setOpenModal(true)}
								isDisabled={isCreatingIncidentActivity || isTicketInProcess}
							/>
						</div>
					)}
				</div>

				{isTicketInProcess ? (
					<div>{translate('TICKET_DETAILS.SYNC_IN_PROGRESS')}</div>
				) : (
					<>
						{paginatedActivities.length > 0 ? (
							paginatedActivities.map((activity, index) => (
								<Note
									key={activity.activityId}
									date={activity.createOn ? activity.createOn.toString() : ''}
									from={activity.from || ''}
									to={activity.to || ''}
									subject={activity.subject || ''}
									content={activity.description || ''}
									createdBy={activity.ownerName || ''}
									isExternal={activity.externalId !== null}
									isFirstNote={index === 0}
									isLastNote={index === paginatedActivities.length - 1}
									attachmentsFile={
										activity.attachments?.map((file) => ({
											fileName: file.fileName || '',
											documentBody: file.documentBody || '',
											mimetype: file.mimeType || '',
											filesize: file.filesize || 0,
										})) || null
									}
								/>
							))
						) : (
							<div>{translate('TICKET_DETAILS.NO_ACTIVITIES_FOUND')}</div>
						)}
					</>
				)}

				<Pagination currentPage={currentPage} totalPages={totalPagesCalculated} onPageChange={onPageChange} />
			</UfinetSectionBox>

			<NotesModal show={openModal} handleClose={() => handleClose()} formik={formik} />
		</>
	)
}

export { DetailTicketNotes }
