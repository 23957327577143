import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

import { ReportOrigin } from '../domain/ReportOrigin'
import { ReportOriginRepository } from '../domain/repository/ReportOriginRepository'

export const HttpReportOriginRepository: (authData: IAuthData) => ReportOriginRepository = (authData) => {
	const url = `${process.env.REACT_APP_URL_BACK_GTI}reportOrigin`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.VITE_API_KEY,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<ReportOrigin[]> =>
			await FetchAPI.get(`${url}/GetReportOrigins`, {
				...fetchOptions,
				...options,
			}),
	}
}
