import React, { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'

import { FormikProps } from 'formik'
import { useClientsFindAllQuery } from 'src/modules/clients/application/ClientsQueries'
import { Client } from 'src/modules/clients/domain/Client'
import { HttpClientsRepository } from 'src/modules/clients/infrastructure/HttpClientsRepository'
import { useNotificationGroupFindAllQuery } from 'src/modules/notificationGroup/application/NotificationGroupQueries'
import { NotificationGroup } from 'src/modules/notificationGroup/domain/NotificationGroup'
import { HttpNotificationGroupRepository } from 'src/modules/notificationGroup/infrastructure/HttpNotificationGroupRepository'
import { useNotificationLanguageFindAllQuery } from 'src/modules/notificationLanguages/application/NotificationLanguageQueries'
import { NotificationLanguage } from 'src/modules/notificationLanguages/domain/NotificationLanguage'
import { HttpNotificationLanguageRepository } from 'src/modules/notificationLanguages/infrastructure/HttpNotificationLanguageRepository'
import { ServicesCustomerDTO } from 'src/modules/servicesCustomer/application/dto/ServicesCustomerDTO'
import { ServicesCustomerFindRequest } from 'src/modules/servicesCustomer/application/dto/ServicesCustomerFindRequest'
import { useCorporateServicesCustomersFindAllQuery } from 'src/modules/servicesCustomer/application/ServicesCustomerQueries'
import { ServicesCustomer } from 'src/modules/servicesCustomer/domain/ServicesCustomer'
import { HttpServicesCustomerRepository } from 'src/modules/servicesCustomer/infrastructure/HttpServicesCustomerRepository'
import {
	AuthContext,
	IUfinetSelectOption,
	onFormikChanges,
	onFormikTextChanges,
	useInternalUser,
	useLang,
	useTranslator,
} from 'ufinet-web-functions'

import { faAdd, faFileEdit } from '@fortawesome/free-solid-svg-icons'

import {
	ContactSelect,
	ContactSelectHandle,
	DatePicker,
	DatePickerTypeEnum,
	TooltipButton,
	UfinetInput,
	UfinetSectionBox,
	UfinetSelect,
} from 'ufinet-web-components'
import { CreateCorporateIncidentFormikType } from '../CreateIncidentFormikType'

interface TicketInfoSectionCorporateProps {
	formik: FormikProps<CreateCorporateIncidentFormikType>
	affectedServices: ServicesCustomerDTO[]
	setShowAffectedServicesModal: (value: React.SetStateAction<boolean>) => void
	addAffectedService: (affectedService: ServicesCustomerDTO) => void
}

const TicketInfoSectionCorporate: FC<TicketInfoSectionCorporateProps> = ({
	formik,
	affectedServices,
	setShowAffectedServicesModal,
	addAffectedService,
}) => {
	const contactRef = useRef<ContactSelectHandle>(null)
	const [inputTimeout, setInputTimeout] = useState<NodeJS.Timeout>()
	const [customerId, setCustomerId] = useState<string | undefined>(undefined)
	const [customersId, setCustomersId] = useState<string[]>([])
	const [corporateGroupId, setCorporateGroupId] = useState<string[] | undefined>(undefined)
	const [notificationGroups, setNotificationGroups] = useState<IUfinetSelectOption[]>([])
	const [notificationLangs, setNotificationLangs] = useState<IUfinetSelectOption[]>([])
	const [servicesCustomer, setServicesCustomer] = useState<ServicesCustomerDTO[]>([])

	const authData = useContext(AuthContext)
	const translate = useTranslator()
	const onChange = useCallback(onFormikChanges, [])
	const onTextChange = useCallback(onFormikTextChanges, [])
	const language = useLang()
	const isInternal = useInternalUser()

	const servicesCustomerRepository = useMemo(() => HttpServicesCustomerRepository(authData), [authData])
	const notificationGroupRepository = useMemo(() => HttpNotificationGroupRepository(authData), [authData])
	const notificationLangRepository = useMemo(() => HttpNotificationLanguageRepository(authData), [authData])
	const clientsRepository = useMemo(() => HttpClientsRepository(authData), [authData])

	useEffect(() => {
		if (customersId.length && customerId === undefined) {
			setServicesCustomer([])
			getServicesCustomer(ServicesCustomerFindRequest.fromValues(customersId, language, undefined, isInternal))
		}
		if (customerId || affectedServices.length > 0) {
			setServicesCustomer([])
			getServicesCustomer(
				ServicesCustomerFindRequest.fromValues([customerId || affectedServices[0].customerId], language)
			)
			contactRef.current?.fillSelect({ clientIds: [customerId || affectedServices[0].customerId] })
		}
		if (corporateGroupId && (customerId || affectedServices.length > 0)) {
			getNotificationGroups({
				corporativeGroupId: corporateGroupId,
				customerId: customerId || affectedServices[0].customerId,
			})
		}
	}, [customersId, customerId, corporateGroupId])

	useClientsFindAllQuery(clientsRepository, {
		onSuccess: (res: Client[]) => setCustomersId(Client.mapClientsToArray(res)),
		onError: () => toast.error(translate('FETCH.ERROR.CLIENT')),
	})

	const { isLoading: loadingNotificationLangs } = useNotificationLanguageFindAllQuery(notificationLangRepository, {
		onSuccess: (params: NotificationLanguage[]): void => {
			const mappedData = params.map((item) =>
				NotificationLanguage.mapNotificationLanguageToSelectOption(item, language)
			)
			setNotificationLangs(mappedData)
		},
	})

	const { mutate: getServicesCustomer, isLoading: loadingServicesCustomer } = useCorporateServicesCustomersFindAllQuery(
		servicesCustomerRepository,
		{
			onSuccess: (res) => {
				const mappedData = ServicesCustomer.mapServicesCustomerToSelectOption(res)
				setServicesCustomer(mappedData)
			},
			onError: () => {
				toast.error(translate('FETCH.ERROR.AFFECTED_SERVICE'))
			},
		}
	)

	const { mutate: getNotificationGroups } = useNotificationGroupFindAllQuery(notificationGroupRepository, {
		onSuccess: (params) => {
			const mappedData = params.map((item) => NotificationGroup.mapNotificationGroupToSelectOption(item))
			setNotificationGroups(mappedData)
		},
		onError: () => {
			toast.error(translate('FETCH.ERROR.NOTIFICATION_GROUP'))
		},
	})

	const handleInputChange = useCallback(
		(global) => {
			if (global) {
				if (inputTimeout) {
					clearTimeout(inputTimeout)
				}

				const timeout = setTimeout(() => {
					if (customerId) {
						setServicesCustomer([])
						getServicesCustomer(ServicesCustomerFindRequest.fromValues([customerId], language, global))
					} else {
						setServicesCustomer([])
						getServicesCustomer(ServicesCustomerFindRequest.fromValues(customersId, language, global, isInternal))
					}
				}, 500)

				setInputTimeout(timeout)
			}
		},
		[customersId, inputTimeout]
	)

	return (
		<UfinetSectionBox title="ticket_info" className="mb-5 p-5">
			<h4>{translate('TICKET.NEW.TITLE.TICKET.INFO')}</h4>
			<div className="row">
				<div className="col-12 col-md-6 mt-3">
					<div className="d-flex gap-3">
						<UfinetSelect
							options={servicesCustomer}
							value={formik?.values?.affectedService}
							id="affected_service"
							labelTitle={translate('TICKET.NEW.AFFECTED_SERVICE')}
							requiredIcon
							className="col"
							// @ts-expect-error comment
							onChange={(e: ServicesCustomerDTO) => {
								if (e) {
									onChange(formik, 'affectedService')(e)
									onChange(formik, 'clientSelect')(e)
									setCustomerId(e.customerId)
									setCorporateGroupId(e.corporativeGroupId)
									return
								}

								onChange(formik, 'affectedService')({ value: '', label: '' })
								if (affectedServices.length === 0) {
									onChange(formik, 'reportContactId')({ value: '', label: '' })
									onChange(formik, 'notificationGroup')({ value: '', label: '' })
									onChange(formik, 'notificationLang')({ value: '', label: '' })
									onChange(formik, 'reportPhone')('')
								}

								setCustomerId(undefined)
								setCorporateGroupId(undefined)
								return
							}}
							onInputChange={handleInputChange}
							error={formik.errors.affectedService?.label}
							isLoadingOptions={loadingServicesCustomer}
							isClearable
							clearValues
						/>
						<div>
							<TooltipButton
								buttonProps={{
									id: 'add_affected_service',
									type: 'button',
									secondaryButton: true,
									icon: faAdd,
									onClick: () => addAffectedService(formik.values.affectedService),
									isDisabled: !formik.values.affectedService.value || !formik.values.clientSelect.value,
									style: { maxWidth: 38, marginTop: 28 },
									className: 'd-flex align-items-center justify-content-center',
								}}
								tooltipProps={{
									className: 'tooltip-dark',
									placement: 'top-end',
									showArrow: false,
								}}
								active
								behaviour="hover"
								hideMethod="unrender"
								children={translate('TICKET.NEW.ADD_AFFECTED_SERVICE')}
							/>
						</div>
						<div
							style={{ marginTop: 28, maxWidth: 38, maxHeight: 38 }}
							className="d-flex align-items-center justify-content-center position-relative"
						>
							<TooltipButton
								buttonProps={{
									id: 'view_affected_service',
									type: 'button',
									secondaryButton: true,
									icon: faFileEdit,
									onClick: () => setShowAffectedServicesModal(true),
									isDisabled: affectedServices.length === 0,
									style: { maxWidth: 38 },
									className: 'd-flex align-items-center justify-content-center',
								}}
								tooltipProps={{
									className: 'tooltip-dark',
									containerPadding: 0,
									placement: 'top-end',
									showArrow: false,
								}}
								active
								behaviour="hover"
								hideMethod="unrender"
								children={translate('TICKET.NEW.VIEW_AFFECTED_SERVICES')}
							/>
							{affectedServices.length > 0 && (
								<div
									className="position-absolute d-flex align-items-center justify-content-center rounded-circle"
									style={{ width: 20, height: 20, backgroundColor: '#0065A4', color: '#fff', top: -5, right: -5 }}
								>
									{affectedServices.length}
								</div>
							)}
						</div>
					</div>
				</div>
				<ContactSelect
					className="col-12 col-md-6 mt-3"
					ref={contactRef}
					isDisabled={customerId === undefined && affectedServices.length === 0}
					error={formik.errors.reportContactId?.label}
					value={formik.values.reportContactId}
					labelTitle={translate('TICKET.NEW.REPORT.USER')}
					requiredIcon
					onChange={onChange(formik, 'reportContactId')}
				/>
			</div>
			<div className="row">
				<UfinetInput
					type="text"
					value={formik.values.reportEmail}
					id="report_email"
					isDisabled
					requiredIcon
					solid={false}
					error={formik.errors.reportEmail}
					labelTitle={translate('TICKET.NEW.REPORT.EMAIL')}
					className="col-12 col-md-6 mt-3"
					onChange={onTextChange(formik, 'reportEmail')}
				/>
				<UfinetSelect
					options={notificationGroups}
					value={formik.values.notificationGroup}
					id="notification_group"
					labelTitle={translate('TICKET.NEW.NOTIFICATION_GROUP')}
					className="col-12 col-md-6 mt-3"
					onChange={onChange(formik, 'notificationGroup')}
					isDisabled={(corporateGroupId === undefined || customerId === undefined) && affectedServices.length === 0}
				/>
			</div>
			<div className="row">
				<UfinetInput
					type="text"
					value={formik.values.reportPhone}
					id="report_phone"
					requiredIcon
					solid={false}
					error={formik.errors.reportPhone}
					labelTitle={translate('TICKET.NEW.REPORT.PHONE')}
					className="col-12 col-md-4 mt-3"
					onChange={onTextChange(formik, 'reportPhone')}
				/>
				<DatePicker
					value={formik.values.creationDate}
					timeFormat="24"
					type={DatePickerTypeEnum.DATE_TIME}
					id="creation_timestamp"
					label={translate('TICKET.NEW.CREATION_DATE')}
					className="col-12 col-md-4 mt-3"
					disabled={true}
					onChange={() => {}}
				/>
				<UfinetSelect
					options={notificationLangs}
					isLoadingOptions={loadingNotificationLangs}
					value={formik.values.notificationLang}
					error={formik.errors.notificationLang?.label}
					id="notification_lang"
					requiredIcon
					labelTitle={translate('TICKET.NEW.NOTIFICATION_LANGUAGE')}
					className="col-12 col-md-4 mt-3"
					onChange={onChange(formik, 'notificationLang')}
				/>
			</div>
		</UfinetSectionBox>
	)
}

export { TicketInfoSectionCorporate }
