import { defaultOptionsFetch, FetchAPI, FetchOptions, IAuthData } from 'ufinet-web-functions'

import { Client } from '../domain/Client'
import { ClientsRepository } from '../domain/repository/ClientsRepository'

export const HttpClientsRepository: (authData: IAuthData) => ClientsRepository = (authData) => {
	const url = `${process.env.REACT_APP_URL_BACK_UFINET_HUB}api`

	const fetchOptions: FetchOptions = {
		...defaultOptionsFetch,
		authenticationData: authData,
		sendApiKey: true,
		apiKey: process.env.REACT_APP_API_KEY,
	}

	return {
		findAll: async (options?: FetchOptions): Promise<Client[]> =>
			await FetchAPI.get(`${url}/client/find`, {
				...fetchOptions,
				...options,
			}),
		findCountries: async (options?: FetchOptions): Promise<Client[]> =>
			await FetchAPI.get(`${url}/countries`, {
				...fetchOptions,
				...options,
			}),
		findCorporatesGroup: async (options?: FetchOptions): Promise<Client[]> =>
				await FetchAPI.get(`${url}/corporate-group`, {
					...fetchOptions,
					...options,
				}),
	}
}
