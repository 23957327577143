import { useQuery } from 'react-query'

import { AttachmentConfig } from '../domain/AttachmentConfig'
import { AttachmentConfigRepository } from '../domain/repository/AttachmentConfigRepository'

export const useAttachmentConfigFindAllQuery = (
	attachmentConfigRepository: AttachmentConfigRepository,
	{
		onSuccess,
		onError,
	}: {
		onSuccess?: (affectationType: AttachmentConfig[]) => void
		onError?: (error: unknown) => void
	} = {}
) => {
	return useQuery('affectation-type-find-all-query', () => attachmentConfigRepository.findAll(), {
		staleTime: 0,
		onSuccess,
		onError,
	})
}
