import { FC, useContext, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { CorporateTicketDetailPage } from '@Pages/tickets/details/CorporateTicketDetailPage'
import { FTTHTicketDetailPage } from '@Pages/tickets/details/FTTHTicketDetailPage'
import { ListIncidentsPage } from '@Pages/tickets/list/ListIncidentsPage'
import { CreateCorporateIncidentPage } from '@Pages/tickets/new/CreateCorporateIncidentPage'
import { CreateFtthIncidentPage } from '@Pages/tickets/new/CreateFtthIncidentPage'
import { CreateIncidentPage } from '@Pages/tickets/new/CreateIncidentPage'
import { AsideDefault } from '@Ui/layout/aside/AsideDefault'
import { AsideMobile } from '@Ui/layout/aside/AsideMobile'
import { SupportPage } from 'src/pages/knowledge/support/SupportPage'
import { WelcomeKitPage } from 'src/pages/knowledge/welcomekit/WelcomeKitPage'
import { BulkTicketsPage } from 'src/pages/tickets/bulk/BulkTicketsPage'
import { AuthContext, useTranslator } from 'ufinet-web-functions'

import { AuthRoute, MasterLayout, WelcomePage } from 'ufinet-web-components'
import {
	PATH_BULK_TICKETS,
	PATH_CORPORATE_TICKETS_DETAIL,
	PATH_FTTH_TICKETS_DETAIL,
	PATH_HOME,
	PATH_LIST_TICKETS,
	PATH_NEW_TICKET,
	PATH_NEW_TICKET_CORP,
	PATH_NEW_TICKET_FTTH,
	PATH_SUPPORT,
	PATH_WELCOME_KIT,
	routerNameMap,
} from './routerNameMap'
import { routerPermissionMap } from './routerPermissionMap'
import { HttpClientsRepository } from 'src/modules/clients/infrastructure/HttpClientsRepository'
import { IIncidentsFilter } from 'src/interfaces/clientRepository/IClientRepository'

const PrivateRoutes: FC = () => {
	const translate = useTranslator()
	const location = useLocation()
	const authData = useContext(AuthContext)

	const [incidentsFilter, setIncidentsFilter] = useState<IIncidentsFilter>({
		corporateGroupIdsList: [],
		customerIds: [],
		countryIds: []
	})

	useEffect(() => {
		const clientsRepository = HttpClientsRepository(authData); // Asumimos que existe una función que retorna el repositorio
	
		async function loadPermissions() {
			try {
				const [permissions, corpsGroup] = await Promise.all([
					clientsRepository?.findAll(),
					clientsRepository?.findCorporatesGroup()
				]);
	
				const corporateGroupsMap = new Map();
				const customerIdsMap = new Map();
				const countryIdsMap = new Map();
	
				corpsGroup.forEach((group) => {
					corporateGroupsMap.set(group.id, { label: group.name, value: group.id });
				});
	
				permissions.forEach((permission) => {
					const existingGroup = corporateGroupsMap.get(permission.corporateGroupId);
					if (existingGroup) {
						corporateGroupsMap.set(permission.corporateGroupId, { label: existingGroup.label, value: permission.corporateGroupId });
					}
	
					customerIdsMap.set(permission.id, { label: permission.name, value: permission.id });
					countryIdsMap.set(permission.countryId, { label: permission.countryId, value: permission.countryId });
				});
	
				setIncidentsFilter({
					corporateGroupIdsList: [...corporateGroupsMap.values()],
					customerIds: [...customerIdsMap.values()],
					countryIds: [...countryIdsMap.values()]
				});
			} catch (error) {
				console.error('Error fetching permissions:', error);
				setIncidentsFilter({
					corporateGroupIdsList: [],
					customerIds: [],
					countryIds: []
				});
			}
		}
	
		loadPermissions();
	}, [authData]);

	return (
		<Routes>
			<Route
				element={
					<MasterLayout
						routerLocation={location}
						asideDefault={<AsideDefault />}
						routerNameMap={routerNameMap}
						asideMobile={<AsideMobile />}
					/>
				}
			>
				<Route
					path={PATH_HOME}
					element={<WelcomePage appName={translate('GTI.NAME')} message={translate('GTI.MESSAGE')} />}
				/>

				<Route
					path={PATH_NEW_TICKET}
					element={
						<AuthRoute authorizedRoles={routerPermissionMap.get(PATH_NEW_TICKET)!}>
							<CreateIncidentPage />
						</AuthRoute>
					}
				/>

				<Route path={PATH_NEW_TICKET_CORP} element={<CreateCorporateIncidentPage />} />
				<Route path={PATH_NEW_TICKET_FTTH} element={<CreateFtthIncidentPage />} />

				<Route path={`${PATH_CORPORATE_TICKETS_DETAIL}/:id`} element={<CorporateTicketDetailPage />} />
				<Route path={`${PATH_FTTH_TICKETS_DETAIL}/:id`} element={<FTTHTicketDetailPage />} />

				<Route path={PATH_BULK_TICKETS} element={<BulkTicketsPage />} />
				<Route path={PATH_LIST_TICKETS} element={<ListIncidentsPage incidentsFilter={incidentsFilter} />} />

				<Route path={PATH_SUPPORT} element={<SupportPage />} />
				<Route path={PATH_WELCOME_KIT} element={<WelcomeKitPage />} />

				<Route path="*" element={<Navigate to={PATH_HOME} replace />} />
			</Route>
		</Routes>
	)
}

export { PrivateRoutes }
